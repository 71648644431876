import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function ueRegistration() {
  // Misc
  const { t } = useI18n();

  // Composables
  const {
    ALL_OPTIONS,
    getUsers,
    getTrainings,
    getCertificates,
    getCollections,
    defaultSystemOptions
  } = useOptions();

  // CONSTANTS
  const view_code = "RegistrationTrainings";
  const route = "teaching-registrations-trainings";
  const endpoint = "teaching.registrations";
  const searchFields = [];
  const types = {
    user_id: inputTypes.SELECT,
    training_id: inputTypes.SELECT,
    collection_id: inputTypes.SELECT,
    registered_via: inputTypes.SELECT,
    registered_on: inputTypes.DATE,
    status: inputTypes.SELECT,
    result: inputTypes.SELECT,
    registration_requested_on: inputTypes.DATE,
    registration_approved_by: inputTypes.SELECT,
    completion_deadline: inputTypes.DATE,
    completion_approval_requested_on: inputTypes.DATE,
    completion_approved_by: inputTypes.SELECT,
    completed_on: inputTypes.DATE,
    completion_comments: inputTypes.TEXT,
    cancelled_on: inputTypes.DATE,
    started_on: inputTypes.DATE,
    certificate_file_id: inputTypes.SELECT,
    required_for_role: inputTypes.SELECT
  };
  const localizedFields = [];
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.trainings"),
      value: "training"
    },
    {
      text: t("app.registered_on"),
      value: "registered_on"
    },
    {
      text: t("app.result"),
      value: "result",
      headerClass: "justify-center"
    },
    {
      text: t("app.status"),
      value: "status"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    user_id: "",
    training_id: "",
    collection_registration_id: "",
    registered_via: ALL_OPTIONS.INSTRUCTOR_ACTION.value,
    registered_on: "",
    status: ALL_OPTIONS.PENDING.value,
    result: ALL_OPTIONS.INCOMPLETE.value,
    registration_requested_on: "",
    registration_approved_by: "",
    completion_deadline: "",
    completion_approval_requested_on: "",
    completion_approved_by: "",
    completed_on: "",
    completion_comments: "",
    cancelled_on: "",
    certificate_file_id: "",
    started_on: "",
    required_for_role: ALL_OPTIONS.NO.value
  });
  const rules = ref({
    user_id: { required },
    training_id: { required },
    collection_registration_id: {},
    registered_via: { required },
    registered_on: {},
    status: { required },
    result: {},
    registration_requested_on: {},
    registration_approved_by: {},
    completion_deadline: {},
    completion_approval_requested_on: {},
    completion_approved_by: {},
    completed_on: {},
    completion_comments: {},
    cancelled_on: {},
    certificate_file_id: {},
    started_on: {},
    required_for_role: {}
  });
  const options = ref({
    user_id: [],
    training_id: [],
    collection_registration_id: [],
    collection_id: [],
    registration_approved_by: [],
    completion_approved_by: [],
    certificate_file_id: [],
    registered_via: [
      ALL_OPTIONS.USER_REQUEST,
      ALL_OPTIONS.INFO_UPDATE,
      ALL_OPTIONS.NIGHT_PROCESS,
      ALL_OPTIONS.INSTRUCTOR_ACTION
    ],
    status: [
      ALL_OPTIONS.PAUSED,
      ALL_OPTIONS.REGISTRATION_REQUESTED,
      ALL_OPTIONS.PENDING,
      ALL_OPTIONS.IN_PROGRESS,
      ALL_OPTIONS.COMPLETION_APPROVAL_REQUESTED,
      ALL_OPTIONS.COMPLETED,
      ALL_OPTIONS.CANCELLED,
      ALL_OPTIONS.DENIED,
      ALL_OPTIONS.WITHDRAWN
    ],
    result: [ALL_OPTIONS.PASSED, ALL_OPTIONS.FAILED, ALL_OPTIONS.INCOMPLETE],
    required_for_role: defaultSystemOptions
  });

  // METHODS
  const getOptions = async () => {
    const users = await getUsers();
    options.value.user_id = users;
    options.value.registration_approved_by = users;
    options.value.completion_approved_by = users;

    options.value.training_id = await getTrainings();
    options.value.collection_id = await getCollections();
    options.value.certificate_file_id = await getCertificates();

    return options.value;
  };

  return {
    headers,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    localizedFields,
    getOptions
  };
}
